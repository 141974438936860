'use strict';

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Link from '../components/Link';

import { graphql } from 'gatsby';

const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
`;

const StyledParagraph = styled.p`
    margin-bottom: 2rem;
    font-size: 2.75rem;
`;

export default ({ data }) => (
    <Layout currentPage="about" data={data}>
        <Helmet>
            <title>About</title>
            <meta name="description" content={data.site.siteMetadata.tagline} />
        </Helmet>

        <AboutContainer>
            <StyledParagraph>
                {'Founder, '}
                <Link href="https://chemicalloche.com" target="_blank">
                    Chemical Loche
                </Link>
                .
            </StyledParagraph>
            <StyledParagraph>
                Entrepreneur. Developer. Designer.
            </StyledParagraph>
            <StyledParagraph>
                OCD-driven Perfectionist. Minimalist.
            </StyledParagraph>
        </AboutContainer>
    </Layout>
);

export const query = graphql`
    query AboutQuery {
        site {
            siteMetadata {
                tagline
            }

            ...FooterFragment
        }
    }
`;
